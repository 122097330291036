<template>
    <Panel class="mt-4">
        <template
            v-if="isMounted"
            #header
            >{{ content.header }}</template
        >
        <template #default>
            <LoadingWrapper :isLoading="isLoading">
                <div :key="mountKey('generic-step-body')">
                    {{ content.body }}
                </div>
                <DebugFrame
                    :key="mountKey('generic-step-debug')"
                    id="generic"
                    :startHidden="frame.startHidden"
                    :debug="frame.isEnabled"
                    :data="frame.data"
                />
            </LoadingWrapper>
        </template>
        <template
            v-if="isMounted"
            #footer
            >{{ content.footer }}</template
        >
    </Panel>
</template>

<script>
    // <!-- API -->
    import {
        defineComponent,
        computed,
        toRefs,
        onMounted,
        onUnmounted,
    } from 'vue';
    import { FormNavigationPolicy } from '@/hooks/useNavigationPolicies';

    // <!-- COMPONENTS -->
    import Panel from '@/components/Panel.vue';
    import LoadingWrapper from '@/components/LoadingWrapper.vue';
    import DebugFrame from '@/components/debug/DebugFrame.vue';

    // <!-- COMPOSABLES -->
    import {
        useDebugFrame,
        DebugObject,
    } from '@/hooks/reactivity/useDebugFrame';
    import { useMounted } from '@vueuse/core';
    import { useMountKey } from '@/hooks/reactivity/useMountKey';
    import { StepContent } from '~CSVUploader/hooks/workflow/useStepContent';

    // <!-- TYPES -->

    /** @typedef {import('@/features/csv-uploader/hooks/form/useUploadForm').UploadFormConfig} UploadFormConfig */

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'GenericFormStep',
        components: {
            Panel,
            LoadingWrapper,
            DebugFrame,
        },
        props: {
            form: {
                /** @type {V.PropType<UploadFormConfig>} */
                type: Object,
                required: true,
            },
        },
        emits: [`set:policies`],
        setup(props, context) {
            // PROPS
            const { form } = toRefs(props);
            const { store } = form.value;

            // COMPOSABLES

            /**
             * Get the component isMounted tracker
             * for use with the loading components.
             */
            const isMounted = useMounted();

            /**
             * Get the component mount key hack
             * used to force updates to the component state
             * when subscribed to certain actions and mutations.
             */
            const {
                getNamespacedMountKey,
                subscribeWatchedActions,
                subscribeWatchedMutations,
                resetMountKey,
            } = useMountKey();

            // COMPUTED PROPERTIES

            /**
             * Create the page content.
             * @type {StepContent}
             */
            const content = StepContent.create('Generic Form Step');

            /**
             * Prepare a loading wrapper conditional.
             */
            const isLoading = computed(() => {
                const isNotReady = isMounted.value !== true;
                return isNotReady;
            });

            // DEBUG

            /**
             * Computed debug frame.
             */
            const frame = computed(
                () => {
                    const options = store.api.state.uploader.config;
                    const records = store.api.state.uploader.data.records;
                    const selectedRecords = [...records.values()].map((r) => {
                        const {
                            flags,
                            status,
                            file,
                            location,
                            suggestedLocation,
                            mappingProfile,
                            suggestedMappingProfile,
                            batch,
                        } = r ?? {};
                        const { name, size, type } = file.value ?? {};
                        return {
                            id: name,
                            file: {
                                name,
                                type,
                                size: `${size} bytes`,
                            },
                            flags: [...flags].join(','),
                            status: [...status].join(','),
                            location: {
                                ...location,
                                suggested: suggestedLocation,
                            },
                            profile: {
                                ...mappingProfile,
                                suggested: suggestedMappingProfile,
                            },
                            batch,
                        };
                    });

                    // Create current step structure.
                    const { id, label, description } =
                        store.api.state.uploader.workflow.currentStep ?? {};
                    const currentStep = {
                        id,
                        label,
                        description,
                    };

                    // Prepare data.
                    const data = [
                        DebugObject.create('Current Step', currentStep),
                        DebugObject.create('Configuration', options),
                        ...selectedRecords.map((r) =>
                            DebugObject.create(`Record`, r)
                        ),
                    ];

                    // Return new frame instance.
                    return useDebugFrame({ data });
                },
                {
                    // onTrack(e) {
                    //     debugger;
                    // },
                    // onTrigger(e) {
                    //     debugger;
                    // },
                }
            );

            // NAVIGATION POLICIES

            const isSubmit = {
                allowed: computed(() => {
                    const hasCurrent =
                        store.api.store.state.uploader.workflow.currentStep !=
                        null;
                    const hasNext =
                        hasCurrent &&
                        store.api.store.state.uploader.workflow.nextStep !=
                            null;
                    return !isLoading.value && hasNext;
                }),
            };

            const submit = FormNavigationPolicy.prepare()
                .type.asSubmit()
                .label.exact('Next')
                .visible.on()
                .enabled.withComputed(isSubmit.allowed)
                .action.onClick(() => {
                    store.goToNextStep(true);
                })
                .create();

            const isReset = {
                allowed: computed(() => {
                    const hasCurrent =
                        store.api.store.state.uploader.workflow.currentStep !=
                        null;
                    const hasPrevious =
                        hasCurrent &&
                        store.api.store.state.uploader.workflow.previousStep !=
                            null;
                    return !isLoading.value && hasPrevious;
                }),
            };

            const reset = FormNavigationPolicy.prepare()
                .type.asReset()
                .label.exact('Back')
                .visible.on()
                .enabled.withComputed(isReset.allowed)
                .action.onClick(() => {
                    store.goToPreviousStep(true);
                })
                .create();

            // LIFECYCLE (Mount)

            onMounted(() => {
                subscribeWatchedActions(store.api.store, [``]);
                subscribeWatchedMutations(store.api.store, [``]);
                context.emit('set:policies', { submit, reset });
            });

            // LIFECYCLE (Unmount)

            onUnmounted(() => {
                isMounted.value = false;
                resetMountKey();
            });

            return {
                // REACTIVITY
                isLoading,
                isMounted,
                mountKey: getNamespacedMountKey,

                // STEP CONTENT
                content,

                // DEBUG
                frame,
            };
        },
    });
</script>
